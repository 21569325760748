@import './global_styles.scss';

.row-main-banner.rastreo-details {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/apartado-rastreo/APARTADO---RASTREO-01-BACKGROUND-VDM.jpg);
  background-position: 0px -70px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
}
.row-main-banner.rastreo {
  background-image: linear-gradient(to right, $blue-color, #0d142d);
  height: 45vh;
  .minibannerRastreo {
    color: #ffffff;
    text-align: center;
    h2 {
      text-transform: uppercase;
      width: 100%;
      font-weight: 900;
      font-size: 3.3rem;
    }
    p {
      width: 100%;
      font-size: 1.2rem;
      font-family: 'Poppins';
      font-weight: 100;
    }
    .btns {
      width: 100%;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        width: 50%;
      }
    }
  }
}
.text-logo-banner.text-logo-banner-row.rastreo {
  background: #fafafa;
  margin-top: 2rem;
}
.input-tracking {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .description {
    text-align: center;
    h3 {
      text-transform: uppercase;
      color: $blue-color;
      font-family: 'Poppins', sans-serif;
      width: 50%;
      margin: 0 auto;
      font-weight: 400;
    }
    p {
      font-size: 1.2rem;
      color: gray;
      &.blue {
        font-size: 0.8rem;
        font-weight: 900;
        color: $blue-color;
      }
    }
    .icon-loc {
      background: $blue-color;
      width: 10vh;
      border-radius: 100px;
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem auto;
      img {
        width: 40%;
      }
    }
  }

  form {
    text-align: center;
    width: 100%;
    button {
      margin: 1.5rem 0;
      width: 30%;
      height: 6vh;
      font-size: 1.2rem !important;
    }
  }
}
.icon-loc-failed {
  width: 40vh;
  border-radius: 100px;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  img {
    width: 40%;
  }
}
.rastreo-failed {
  margin: 5rem 0;
  text-align: center;
  h2 {
    margin-top: 4rem;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 3rem;
    color: $blue-color;
  }
  .blue {
    u {
      font-weight: bolder;
      color: $blue-color;
    }
  }
  .btn-light-blue {
    font-size: 1.3rem !important;
  }
}
.blue-banner {
  background-image: linear-gradient(to right, $blue-color, #0b1429);
  &.rastreo {
    text-transform: uppercase;
    font-weight: bolder;
    h2 {
      font-size: 3rem;
    }
  }
}
.container {
  padding: 0 7rem;
}
.input-tracking-details {
  .accordion {
    margin: 4rem 0;
    & .accordion-header {
      button {
        span {
          color: $blue-color;
          font-weight: 900;
          &.blue {
            color: #30c0cc;
          }
        }
      }
    }
  }
  .first-row {
    margin: 3rem 0 1rem 0;
    .text-right {
      text-align: right;
    }
    span {
      font-size: 1.5rem;
      font-weight: 300;
      color: $blue-color;
    }
  }
  .second-row {
    padding: 0 2.3rem 2rem;
    .card {
      border: 1px solid gray;
      border-radius: 10px;
      padding: 1rem 2rem;
      span {
        color: gray;
        font-weight: 100;
        font-size: 1rem;
      }
      p {
        font-weight: bold;
        color: $blue-color;
        font-size: 1.3rem;
        &.no-margin {
          margin: 0;
        }
      }
    }
    .details {
      padding: 2rem 4rem;
      .title {
        font-weight: 500;
        color: $blue-color;
        font-size: 1.3rem;
      }
      span {
        color: gray;
        font-weight: 100;
        font-size: 1rem;
      }
      p {
        font-weight: bold;
        color: $blue-color;
        font-size: 1.3rem;
        &.no-margin {
          margin: 0;
        }
      }
    }
  }
  .third-row {
    margin-top: 2rem;
    .location {
      padding: 2rem;
      font-size: 1.1rem;
      display: flex;
      justify-content: space-between;
      span {
        color: gray;
        b {
          font-weight: bolder;
          color: $blue-color;
        }
        &:nth-child(2) {
          color: #30c0cc;
          font-weight: 300;
        }
      }
    }
    .doubts-row {
      padding: 2rem 0;
      display: flex;
      justify-content: space-between;
      .txt {
        padding-left: 3rem;
        p {
          width: 80%;
          color: gray;
        }
      }
      .btn {
        .btn {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.row-pkg-details {
  padding: 0 2rem;
  h2 {
    font-weight: bold;
    color: $blue-color;
  }
  .col-md-11 {
    background: #fcfcfc;
    margin: 0 2rem;
    .dateDetail {
      font-weight: 100;
      margin-bottom: 0;
      margin-top: 0.5rem;
    }
    .descDetail {
      font-weight: 600;
      text-transform: uppercase;
      color: $blue-color;
    }
  }
}

@media (min-width: 390px) and (max-width: 770px) {
  .row-main-banner.rastreo {
    height: 70vh;
  }
  .input-tracking {
    .description {
      h3 {
        width: 90%;
      }
    }
    .form-container {
      width: 80%;
      form {
        button {
          width: 60%;
        }
      }
    }
  }
  .mobile-container {
    padding: 0 2rem;
  }
  .input-tracking-details {
    display: flex;
    justify-content: center;
    .first-row {
      .text-right {
        text-align: left;
      }
    }
  }
  .row-pkg-details {
    .col-md-11 {
      margin: 0;
    }
  }
}
