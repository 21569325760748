@import './global_styles.scss';

.row-main-banner.servicio {
  background-image: url(../assets/Ajustes-Servicios.jpg);
  background-position-y: 80%;
  .right-panel {
    h2 {
      color: $blue-color;
    }
    p {
      &:first-child {
        text-transform: uppercase;
        font-size: 1.5rem;
        margin: 0;
        font-weight: 300;
        line-height: 1.5rem;
        color: gray;
      }
      &.less-width {
        color: gray;
        text-transform: inherit;
        font-weight: 200;
      }
      b {
        font-weight: 900;
        color: $blue-color;
      }
    }
    .card {
      background: #fafafa;
      border-radius: 20px;
      padding: 2rem;
      border: none;
    }
  }
}
.txtbanner {
  height: 35vh;
  background-image: linear-gradient(to right, $blue-color, #0d142d);
  .minibannerRastreo {
    color: #ffffff;
    text-align: center;
    padding-top: 4rem;
    h2 {
      text-transform: uppercase;
      width: 100%;
      font-weight: 900;
      font-size: 3.3rem;
    }
    p {
      width: 100%;
      font-size: 1.2rem;
      font-family: 'Poppins';
      font-weight: 100;
    }
    .btns {
      width: 100%;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        width: 50%;
      }
    }
  }
}
.text-img-banner {
  &-row {
    .background-img-1 {
      background-image: url(../assets/apartado_servicios/repartidor-primer-plano-dando-caja.jpg);
      height: 100%;
      background-size: cover;
    }
    .background-img-2 {
      background-image: url(../assets/apartado_servicios/trabajador-fabrica-lista-verificacion-almacen.jpg);
      height: 100%;
      background-size: cover;
    }
    .background-img-3 {
      background-image: url(../assets/apartado_servicios/primer-plano-repartidor-cerrando-caja-carton-cinta-mientras-prepara-paquetes-su-envio.jpg);
      height: 100%;
      background-size: cover;
    }
    .background-img-4 {
      background-image: url(../assets/apartado_servicios/usando-servicio-entrega-rapida.jpg);
      height: 100%;
      background-size: cover;
    }
    .background-img-5 {
      background-image: url(../assets/apartado_servicios/mensajero-que-entrega-paquete-al-cliente.jpg);
      height: 100%;
      background-size: cover;
    }
    .background-img-6 {
      background-image: url(../assets/apartado_servicios/cajas-paquetes-carga-envio-contenedor-carga-almacen-logistica-envio-camiones-carga.jpg);
      height: 100%;
      background-size: cover;
    }
  }
  &.section-3.servicios {
    background: #fafafa;
    .thrd-p {
      width: 75%;
      span {
        font-weight: bold;
        color: $blue-color;
      }
    }
  }
}
