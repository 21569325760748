@import './global_styles.scss';

.row-main-banner.cobertura {
  background-image: url(../assets/Nuevo-Banner-APARTADO---COBERTURA.jpg);
  background-position: 0px -20px;
  background-repeat: no-repeat;
  background-size: cover;
  .right-panel {
    p {
      width: 65%;
      font-weight: 200;
    }
  }
}

.text-img-banner-cobertura {
  height: 90vh;
  align-items: baseline;

  .map-img {
    width: 65%;
    margin-top: 7vh;
    margin-left: 7vh;
  }
  .text-container {
    .content {
      padding-left: 22%;
      position: relative;
      bottom: 20vh;
      img {
        width: 50%;
      }
      h4 {
        font-size: 2.5rem;
        font-weight: 400;
        text-transform: uppercase;
        color: #30c0cc;
        margin-top: 4rem;
        width: 130%;
      }
      h2 {
        width: 110%;
        font-size: 3.5rem;
        color: $blue-color;
        font-weight: bold;
        text-transform: uppercase;
      }
      p {
        font-weight: 200;
      }
    }
  }
}

.blue-banner {
  background: $blue-color;
  color: #fff;
  padding: 2rem 9.7rem;
  h1 {
    font-size: 4rem;
    font-weight: 100;
  }
}

.delivery-img {
  background-image: url(../assets/Apartado-Cobertura/repartidores-cargando-cajas-carton-furgoneta-mientras-preparan-envio.jpg);
  height: 51vh;
  background-size: cover;
  background-position-y: 80%;
}

.hands-img {
  background-image: url(../assets/Apartado-Cobertura/joven-mujer-feliz-pie-puerta-estrechando-mano-repartidor-mientras-recibe-paquete.jpg);
  height: 33vh;
  background-size: cover;
  background-position-y: 86%;
}

.light-blue-banner {
  background: #30c0cc;
  color: #fff;
  padding: 2rem 9.7rem;
  h1 {
    font-size: 4rem;
    font-weight: 100;
  }
}

.list-of-cities {
  margin: 4rem 1rem 2rem 9rem;
  .list-col {
    h4 {
      text-transform: uppercase;
      color: $blue-color;
      font-weight: 900;
    }
    ul li {
      list-style: none;
    }
    ul {
      padding: 0;
    }
  }
}

@media (min-width: 390px) and (max-width: 770px) {
  .list-of-cities {
    margin: 3rem 5rem;
  }
  .text-img-banner-cobertura {
    height: 160vh;
    .text-container {
      .content {
        padding-left: 5%;
        bottom: 0;
        top: 10vh;
        h4 {
          font-size: 2rem;
          width: 100%;
        }
        h2 {
          font-size: 2.5rem;
          width: 100%;
        }
      }
    }
    .map-img {
      margin-left: 5%;
      width: 90%;
      margin-top: 13vh;
    }
    .camion-img {
      margin-left: 20%;
      width: 80%;
    }
  }
  .blue-banner,
  .light-blue-banner {
    padding: 2rem;
    h1 {
      font-size: 3rem;
    }
  }
}
