@import './global_styles.scss';

.mini-header-navbar {
  display: none;
}
.mini-header {
  background: $blue-color;
  color: white;
  display: flex;
  justify-content: space-between;
  width: 101%;
  height: 5vh;
  align-items: center;
  .mail-phone {
    margin-left: 2rem;
    color: white;
    p {
      font-size: 0.8em;
      margin: 0;
      font-family: 'Poppins', sans-serif;
    }
    .iconEnvelope {
      margin-right: 0.5rem;
    }
    .iconPhone,
    .barSpace {
      margin: 0 0.5rem;
    }
  }
  .social-media {
    margin-right: 2rem;
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
    }
  }
}
.bg-light-rewrite {
  background: white !important;
}
.menu-logo {
  width: 15%;
  text-align: center;
  img {
    width: 65%;
    margin-left: 5.5rem;
  }
}
nav {
  .offcanvas {
    align-items: center;
  }
}
.uppercase {
  text-transform: uppercase;
}
.blue-txt {
  color: #171b74;
  font-weight: 700;
}
.menu-items {
  a {
    margin: 0 1rem;
    text-transform: uppercase;
    color: #0f1577;
    font-weight: 600;
    font-size: 0.9em;
    font-family: 'Poppins', sans-serif;
  }
}
.btn-light-blue {
  background: #30c0cc !important;
  border-color: #30c0cc !important;
  text-transform: uppercase;
  font-size: 0.9em !important;
  font-weight: 700 !important;
}
.btn-aboutus {
  background: transparent !important;
  border-color: #30c0cc !important;
  text-transform: uppercase;
  font-size: 0.9em !important;
  font-weight: 700 !important;
}
.btn-blue {
  background: #171b74 !important;
  border-color: #171b74 !important;
  text-transform: uppercase;
  font-size: 0.9em !important;
  font-weight: 700 !important;
}
// .container-main-banner {
//     height: 80vh;
//     display: flex;
// }
.capitals {
  text-transform: uppercase;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
.row-main-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/Banner-principal-home.jpg);
  background-position: 0px -60px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  .right-panel {
    // padding: 0px 20px 20px 40px;
    align-items: center;
    display: grid;
    width: 45%;
    position: relative;
    top: -10px;
    &.cobertura h2 {
      width: 60%;
    }
    h2 {
      color: white;
      font-weight: 700;
      font-size: 2.5em;
      width: 90%;
      font-family: 'Roboto', sans-serif;
    }
    p {
      color: white;
      width: 80%;
      font-family: 'Roboto', sans-serif;
    }
    .btns {
      width: 65%;
      height: 5.5vh;
      display: flex;
      justify-content: space-between;
      font-family: 'Roboto', sans-serif;
      button {
        width: 45%;
      }
    }
  }
}
.box-tracking {
  background: rgba(4, 6, 52, 0.516);
  color: white;
  border-radius: 20px;
  height: 45vh;
  padding: 45px 40px !important;
  h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
  .form-tracking {
    button {
      width: 50%;
      margin: 15px 0;
      padding: 0.55rem;
      font-family: 'Roboto', sans-serif;
    }
    display: grid;
    .notfount-link {
      color: gray;
      font-size: 0.7em;
      font-family: 'Roboto', sans-serif;
    }
  }
}

.main-mini-banner {
  .items-banner {
    display: flex;
    color: #fff;
    &.bg-light-blue {
      background: #30c0cc;
      .box {
        text-align: center;
        margin: 2rem;
        svg {
          font-size: 2rem;
          margin-top: 1.5rem;
        }
        p {
          &.label {
            text-transform: uppercase;
            font-size: 0.8rem;
            margin: 0.5rem 0 0 0;
          }
          &.secondary-label {
            font-weight: 700;
            font-size: 2rem;
          }
        }
      }
    }
    &.bg-blue {
      background-image: linear-gradient(to right, $blue-color, #0d142d);
      height: 29vh;
      padding-top: 1.5rem;
      .space-left {
        margin-left: 10%;
      }
      .space-right {
        margin-right: 10%;
      }
      .box {
        background: $blue-color;
        border-radius: 5px;
        height: 20vh;
        margin: 1rem 0.4rem;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        padding: 1rem 0.5rem;
        p {
          width: 60%;
          margin: 0 auto;
          font-size: 0.9rem;
          &:last-child {
            width: 70%;
          }
        }
        img {
          width: 35%;
          margin: 0.8rem 0 1rem 0;
          &.costo {
            margin-bottom: 0.3rem;
          }
          &.atencion {
            width: 30%;
            margin-bottom: 0.8rem;
          }
          &.manos {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

.text-logo-banner {
  height: 30vh;
  justify-content: center;
  align-items: center;
  &-col {
    padding-left: 3rem !important;
  }
  .description {
    width: 90%;
  }
  &-img {
    img {
      width: 70%;
      margin-left: 3rem;
    }
  }
}

.text-img-banner {
  height: 55vh;
  align-items: center;
  display: flex;
  justify-content: center;
  &.section-3 {
    height: 65vh;
  }
  &.section-2 {
    background-image: url(../assets/Banner-2.jpg);
    background-size: cover;
    background-position: 0px -120px;
  }
  &-row {
    display: contents !important;
    align-items: center;
    .background-img {
      background-image: url(../assets/repartidores-cargando-cajas-carton-furgoneta-mientras-preparan-envio.jpg);
      height: 100%;
      background-size: cover;
    }
  }

  &-row {
    &-section-2 {
      color: white;
      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        width: 50%;
        &:first-child {
          font-weight: 500;
          color: #9093e2;
          text-transform: uppercase;
          width: 60%;
        }
      }
      h1 {
        font-weight: bold;
        width: 80%;
        text-transform: uppercase;
        width: 70%;
      }
      button {
        padding: 0.55rem 2rem;
      }
    }
    &-section-3 {
      font-family: 'Poppins', sans-serif;
      .content {
        padding: 1rem 0 1rem 5rem !important;
        h2 {
          font-weight: 500;
          text-transform: uppercase;
          margin: 0;
          &:first-child {
            color: $blue-color;
          }
          color: gray;
        }
        h1 {
          margin: 0;
          font-weight: bolder;
          text-transform: uppercase;
          color: $blue-color;
          span {
            font-weight: 500;
          }
        }
        p {
          &.first-p {
            text-transform: uppercase;
            margin: 1.5rem 0 0 0;
            color: #30c0cc;
            font-size: 1.5rem;
            font-weight: 500;
          }
          &.scnd-p {
            font-weight: bold;
            color: $blue-color;
            font-size: 2rem;
            text-transform: uppercase;
            width: 80%;
            line-height: 30px;
          }
          &.thrd-p {
            width: 60%;
            font-weight: 300;
            color: gray;
          }
        }
        & .btn-light-blue {
          padding: 0.55rem 2rem;
        }
      }
    }
  }
}

.service-home-secction {
  height: 130vh;
  background: #fafafa;
  .service-blocks {
    padding: 0 3vh;
    &.first {
      padding-top: 5vh;
    }
    &.second {
      margin-top: 2rem;
    }
    // .box-service {
    //   &:nth-child(even) {
    //     .circle {
    //       border: 1px solid $blue-color;
    //     }
    //   }
    // }
    // &:nth-child(odd) {
    //   .box-service {
    //     .circle {
    //       border: 1px solid #30c0cc;
    //     }
    //   }
    // }
    .box-service {
      transition: 0.5s;
      border-radius: 20px;
      padding: 0;
      height: 45vh;
      margin: 0 3vh;
      p {
        font-family: 'Poppins', sans-serif;
        font-size: 0.8rem;
        font-weight: 300;
        color: gray;
      }
      &:hover {
        background: #fff;
      }
      .btn-blue,
      .btn-light-blue {
        padding: 0.55rem 2.5rem;
      }
      &.serv1 {
        background-image: url(../assets/RECOLECCION-Y-ENTREGA-ADOMICILIO.png);
        background-size: auto;
        background-repeat: no-repeat;
      }
      .gradient-hover {
        background: transparent;
        height: 40vh;
        width: 100%;
        display: block;
        top: 13%;
        position: relative;
        border-radius: 20px;
        padding: 7rem 2rem 0 2rem;
        h4,
        button {
          display: none;
        }
        &:hover {
          transition: 0.5s;
          background-image: linear-gradient(to top, $blue-color, transparent);
          h4 {
            font-weight: 900;
            color: white;
            width: 90%;
            margin: 1rem auto;
          }
          h4,
          button {
            display: block;
            margin-left: 1rem;
          }
        }
        .btn {
          background: transparent !important;
          border-color: #fafafa !important;
          font-size: 0.9em !important;
          font-weight: 700 !important;
        }
      }
      &.serv2 {
        background-image: url(../assets/SEGURO-DE-MERCANCIA.png);
        background-size: auto;
        background-repeat: no-repeat;
      }
      &.serv3 {
        background-image: url(../assets/EMPLAYE-DE-MERCANCIA.png);
        background-size: auto;
        background-repeat: no-repeat;
      }
      &.serv4 {
        background-image: url(../assets/ENVIOS-A-OCURRE.png);
        background-size: auto;
        background-repeat: no-repeat;
      }
      &.serv5 {
        background-image: url(../assets/RETORNO-DE-EVIDENCIAS-FISICAS-O-DIGITALES.png);
        background-size: auto;
        background-repeat: no-repeat;
      }
      &.serv6 {
        background-image: url(../assets/UNIDADES-ESPECIALIZADAS-DE-PAQUETERIA.png);
        background-size: auto;
        background-repeat: no-repeat;
      }
    }
  }
  &-container {
    padding: 7vh 15vh 5vh 15vh !important;
  }
  &-title {
    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      font-size: 0.8rem;
      margin: 0;
    }
    h1 {
      text-transform: uppercase;
      color: #171b74;
      font-weight: 700;
    }
    .blue-line-decorator {
      width: 5%;
      height: 0.2vh;
      background: #30c0cc;
      margin-left: 0.8rem;
    }
  }
}

.footer-large {
  background: $blue-color;
  color: #fff;
  height: 70vh;
  &-col-2-main {
    margin: 0 2rem 0 9rem;
    padding: 1rem 0 1rem 3rem !important;
    h2 {
      margin-top: 2rem;
    }
    img {
      width: 50%;
      margin-top: 3rem;
    }
  }
  &-col-1 {
    display: flex;
    &--main-text {
      font-size: 0.8rem;
      margin-bottom: 2.5rem;
      width: 90%;
      text-align: justify;
      margin-top: 2rem;
    }
    .icon {
      width: 10%;
      text-align: center;
      svg {
        color: #30c0cc;
        font-size: 1.5rem;
      }
      &-text {
        margin-left: 0.5rem;
        font-size: 0.9rem;
        p {
          &.no-margin {
            margin: 0;
            color: #fff;
          }
          margin-bottom: 1.5rem;
          color: gray;
        }
      }
    }
  }
  &-col-2 {
    padding: 7.5rem 0rem 0 3rem !important;
    .blue-line-decorator {
      width: 5%;
      height: 0.2vh;
      background: #30c0cc;
      position: absolute;
    }
    ul {
      padding: 2rem 0 0 0;
    }
    ul li {
      padding: 0.5rem 0;
      list-style: none;
      border-bottom: 0.5px solid #2b41b4;
      width: 60%;
      font-size: 0.9rem;
      font-weight: 300;
      &:last-child {
        border-bottom: 0px;
      }
      svg {
        font-size: 0.7rem;
        margin-right: 1rem;
      }
    }
  }
  &-col-3 {
    margin: 0 8rem 0 0;
    padding: 7.5rem 4rem 0 0 !important;
    h5 {
      b {
        font-size: 1.25rem;
      }
      span {
        font-weight: 500;
      }
    }
    .blue-line-decorator {
      width: 5%;
      height: 0.2vh;
      background: #30c0cc;
      position: absolute;
    }
    p {
      font-weight: 300;
      font-size: 0.8rem;
      margin: 2rem 0 1rem 0;
    }
    .social-media {
      h4 {
        margin-top: 2rem;
        width: 130%;
        font-size: 1.1rem;
      }
      svg {
        font-size: 1.7rem;
        margin: 0 1rem;
      }
    }
    .form-tracking {
      .inputs-form {
        span {
          color: white;
          border: 0;
          background: #132685;
          border-radius: 2px;
        }
        input {
          color: white;
          border: 0;
          background: #132685;
          border-radius: 2px;
          height: 6vh;
        }
      }
      button {
        padding: 0.55rem 1.5rem;
      }
    }
  }
}

.sucursales {
  background: #0d1b62;
  color: white;
  padding: 3rem 10rem;
  padding-top: 4rem;
  text-align: center;
  h5 {
    font-weight: 600;
  }
}

.mini-footer {
  background: $blue-color;
  color: #fff;
  padding: 1rem 3rem;
  text-align: center;
  align-items: center;
  .menu-footer {
    display: flex;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  .copyright {
    p {
      margin: 0;
      font-size: 0.8rem;
    }
  }
}

// Responsive design
@media (min-width: 390px) and (max-width: 770px) {
  body {
    overflow-x: hidden;
    max-width: 100vw;
  }
  nav {
    img {
    }
    button {
      position: relative;
      left: 40%;
    }
  }
  .mini-header-navbar {
    display: block !important;
  }
  .mini-header {
    display: none;
    &-navbar {
      color: $blue-color;
      width: 100%;
      height: 10vh;
      .mail-phone {
        .iconEnvelope {
          margin-right: 0.5rem;
        }
        .iconPhone {
          margin-right: 0.5rem;
        }
      }
      .social-media {
        margin-right: 2rem;
        svg {
          margin: 0 0.5rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  .menu-logo {
    img {
      width: 300%;
      margin-left: 3rem;
    }
  }
  .row-main-banner {
    height: 120vh;
    margin-bottom: -60px !important;
    display: block !important;
    .right-panel {
      width: 100%;
      padding: 4rem 2rem;
      .btns {
        width: 100%;
      }
    }
  }

  .main-mini-banner {
    .items-banner {
      height: auto !important;
      display: block !important;
      padding: 5vh 0 !important;
      .box {
        width: 70%;
        margin-left: 15% !important;
        img {
          margin: 0.5rem 0 1rem 0 !important;
          width: 30% !important;
          &.costo {
            margin: 0 0 0.5rem 0 !important;
          }
          &.atencion {
            width: 20% !important;
          }
        }
      }
    }
  }

  .text-logo-banner {
    height: 60vh;
  }

  .service-home-secction {
    height: auto;
    &-container {
      padding: 7vh 5vh !important;
      height: auto;
    }
    .service-blocks {
      padding: 2rem 0;
      display: block;
      .box-service {
        text-align: center;
        height: auto !important;
        margin: 0 0 1rem 0 !important;
        width: 100% !important;
        h5 {
          margin-top: 2rem;
        }
      }
    }
  }

  .text-img-banner {
    &.section-2 {
      display: block;
      padding: 7vh 5vh;
      background-position: 0 !important;
    }
    &-row-section-2 {
      position: relative;
      top: -10px;
      p {
        &:first-child {
          width: 100% !important;
        }
        width: 90% !important;
      }
      h1 {
        width: 100% !important;
      }
    }
    &.section-3 {
      height: auto !important;
      .text-img-banner-row {
        .background-img {
          height: 20vh !important;
          background-size: cover !important;
          background-position-y: 90% !important;
        }
        & .content {
          padding: 7vh 5vh !important;
        }
      }
    }
  }

  .footer-large {
    height: auto;
    width: 103vw;
    overflow-x: hidden;
    margin: 0 !important;
    .footer-large-col-2-main {
      margin: 0;
    }
    .footer-large-col-2,
    .footer-large-col-3 {
      padding: 2rem 3rem !important;
    }
    .footer-large-col-3 {
      margin: 0;
    }
  }

  .sucursales {
    padding: 3rem;
  }

  .mini-footer {
    .menu-footer {
      display: none;
    }
  }
}
