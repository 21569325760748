@import './global_styles.scss';

.main-banner-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/contactanos/APARTADO-CONTACTANOS.jpg);
  background-position: 0px -60px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  color: #fff;
  font-family: 'Poppins', sans-serif;

  h1 {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }
  p {
    font-weight: 300;
    width: 60%;
  }
  button {
    margin: 15px 0;
    padding: 0.55rem 2rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    background-color: #30c0cc;
    text-transform: uppercase;
    border: transparent;
    font-size: 0.9rem;
  }
}

.wapp-section {
  height: 25vh;
  margin-bottom: 3rem;
  align-content: center;
  align-items: center;
  color: #fff;
  background-image: linear-gradient(to right, $blue-color, #0d142d);
  p {
    width: 67.5%;
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
  }
  button {
    background: #11ca51;
    padding: 0.7rem 1.7rem;
    border: transparent;
    font-weight: 700;
  }
}

.from-contact {
  margin: 5rem 0 20vh 0;
  .container-center {
    padding: 2rem 3rem 0 3rem;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 1);
    box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 1);
    p {
      font-size: 1.1rem;
    }
    .flex {
      display: flex;
      .col {
        padding: 0;
      }
      &.margin-top {
        input {
          margin-top: 0.7rem;
        }
      }
      input {
        width: 95%;
      }
    }
    textarea {
      width: 97.5%;
      margin-top: 0.7rem;
      height: 15vh;
    }
    .submit {
      padding: 0.7rem 1.7rem;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      top: 2rem;
      left: 78%;
      background: #30c0cc;
      border: transparent;
      font-size: 1.2rem;
    }
  }
}

.text-img-banner {
  .circle-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../assets/contactanos/FONDO-REPARTIDOR.jpg);
    height: 60vh;
    background-size: cover;
    width: 60vh;
    border-radius: 300px;
    position: relative;
    left: 25%;
    bottom: 40px;
    background-position-x: 20%;
    img {
      width: 90%;
      position: relative;
      left: 11%;
      bottom: 5%;
    }
  }
  .text-container {
    .content {
      margin-bottom: 5em;
      h2 {
        text-transform: uppercase;
        color: $blue-color;
        font-weight: 800;
        font-size: 2.5rem;
        width: 70%;
      }
      .contact-data {
        margin: 2rem 0;
        &-row {
          display: flex;
          .icon {
            width: 7%;
            text-align: end;
            svg {
              color: #30c0cc;
              font-size: 1.5rem;
            }
            &-text {
              margin-left: 0.5rem;
              font-size: 0.9rem;
              p {
                &.no-margin {
                  margin: 0;
                  color: $blue-color;
                }
                margin-bottom: 1rem;
                color: gray;
              }
            }
          }
        }
      }
      .social-media {
        display: flex;
        width: 50%;
        margin-left: 1rem;
        justify-content: space-between;
        .icons {
          svg {
            font-size: 2rem;
            margin-right: 1rem;
          }
        }
        .btn-wapp {
          background: #11ca51;
          border: transparent;
          padding: 0.7rem 1.7rem;
          font-weight: 700;
        }
      }
    }
  }
}

.map-section {
  iframe {
    z-index: 10;
  }
}

@media (min-width: 390px) and (max-width: 770px) {
  .main-banner-contact {
    margin-bottom: -4rem;
    h1 {
      font-size: 1.5rem;
    }
    p {
      width: 100%;
    }
  }

  .wapp-section {
    height: 32vh;
    text-align: center;
    div {
      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  .from-contact {
    .container-center {
      .submit {
        left: 64%;
      }
    }
  }

  .text-img-banner {
    height: auto;
    overflow: hidden;
    .circle-img {
      height: 50vh;
      width: 50vh;
      margin: 5rem auto 0 auto;
      left: 0;
    }
    .text-container {
      .content {
        padding: 0 2rem;
      }
    }
    .social-media {
      display: block !important;
      .icons {
        margin-bottom: 2rem;
      }
    }
  }

  .map-section {
    iframe {
      padding: 0;
    }
  }
}
